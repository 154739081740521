/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'examManagement': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512 674.64a51.04 51.04 0 01-25.99-7.12L89.13 433.24C73.39 423.96 64 407.51 64 389.23c-.01-18.28 9.39-34.74 25.13-44.03l396.89-234.29a51.09 51.09 0 0151.96 0L934.87 345.2c15.74 9.3 25.13 25.75 25.13 44.03s-9.39 34.72-25.13 44.01l-396.89 234.3c-8 4.74-16.99 7.1-25.98 7.1zm.81-52.49c.01 0 .01.01.01.01s0-.01-.01-.01zM118.24 389.22L512 621.67l393.76-232.45L512 156.78 118.24 389.22z" _fill="#333"/><path pid="1" d="M512 920.19c-87.01 0-170.62-26.31-241.82-76.1-20.14-14.09-32.17-37.32-32.17-62.12V513.23c0-14.55 11.8-26.35 26.35-26.35s26.35 11.8 26.35 26.35v268.73c0 7.62 3.62 14.69 9.68 18.94 62.28 43.56 135.46 66.58 211.61 66.58 76.14 0 149.32-23.02 211.6-66.58 6.06-4.25 9.68-11.32 9.68-18.94V513.23c0-14.55 11.8-26.35 26.35-26.35s26.35 11.8 26.35 26.35v268.73c0 24.81-12.03 48.03-32.17 62.12C682.62 893.87 599 920.19 512 920.19zM115.93 700.35c-14.55 0-26.35-11.8-26.35-26.35V426.37c0-14.55 11.8-26.35 26.35-26.35s26.35 11.8 26.35 26.35V674c.01 14.55-11.79 26.35-26.35 26.35z" _fill="#333"/><path pid="2" d="M127.51 799.41h-23.17c-21.8 0-39.53-17.73-39.53-39.53v-72.7c0-21.8 17.73-39.53 39.53-39.53h23.17c21.8 0 39.53 17.73 39.53 39.53v72.7c0 21.8-17.73 39.53-39.53 39.53z" _fill="#333"/>'
  }
})
