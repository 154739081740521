/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'examRoom': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M745.11 256.5c-168.88-59.63-269.03-89.45-300.45-89.45s-131.57 30.62-300.45 91.87l-18.31 85.11h637.53l-18.32-87.53zM579.7 631.98c.32-80.96 55.02-151.75 133.71-173.05v-62.48H186.03V815.1h493.18c-58.68-43.55-95.08-110.53-99.51-183.12zm-182.79 78.28h-52.8v-209h52.82v209h-.02zm158.08 0h-52.82v-209h52.82v209z"/><path pid="1" d="M761.13 468.68c-90.86 0-164.5 73.11-164.5 163.29 0 136.07 164.5 218.06 164.5 218.06s164.5-81.63 164.5-218.06c0-90.19-73.65-163.29-164.5-163.29zm0 218.06c-30.29 0-54.84-24.37-54.84-54.43s24.55-54.43 54.84-54.43 54.84 24.37 54.84 54.43-24.56 54.43-54.84 54.43z"/>'
  }
})
