import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { login, logout, getUserInfo } from '@/api/users'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import { Loading } from 'element-ui'
import store from '@/store'

export interface IUserState {
  token: string,
  name: string,
  userId: string,
  avatar: string,
  roles: string[]
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || "";
  public name = "";
  public username = "";
  public userId = "";
  public avatar = "";
  public roles: string[] = []

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_USERNAME(username: string) {
    this.username = username
  }

  @Mutation
  private SET_USERID(userId: string) {
    this.userId = userId
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Action({ rawError: true })
  public async Login(userInfo: { username: string, password: string }) {
    let jwtusername = userInfo.username.trim();
    let jwtpassword = userInfo.password.trim();
    const { data } = await login({ username: jwtusername, password: jwtpassword });
    setToken(data.token);
    this.SET_TOKEN(data.token);
    this.SET_USERNAME(data.username);
  }

  @Action
  public ResetToken() {
    removeToken()
    this.SET_TOKEN('');
    this.SET_USERNAME('');
    this.SET_ROLES([])
  }

  @Action
  public async GetUserInfo() {
    if (this.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const { data } = await getUserInfo()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    // const { roleID, userName, userID } = data
    const name = data.name;
    const username = data.username;
    const userId = data.userId
    const roleId = data.roles;
    const avatar = data.avatar;
    //const roles = data.roles || [];
    // roles must be a non-empty array
    if (!roleId || roleId.length <= 0) {
      throw Error('GetUserInfo: roles must be a non-null array!')
    }
    this.SET_ROLES(roleId);
    this.SET_NAME(name);
    this.SET_USERNAME(username);
    this.SET_USERID(userId);
    this.SET_AVATAR(avatar);
  }

  @Action
  public async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    // await logout()
    removeToken()
    this.SET_TOKEN('')
    this.SET_ROLES([])
  }
}

export const UserModule = getModule(User)
