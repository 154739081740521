/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'setup': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M56.889 284.444h853.333v682.667H56.89V284.444zm56.889 56.89v568.888h739.555V341.333H113.778zm85.333-170.667l-73.955 113.777H56.889l113.778-170.666h625.777l113.778 170.666h-68.266L768 170.667H199.111zm-73.955 113.777H56.889l113.778-170.666h625.777l113.778 170.666h-68.266L768 170.667H199.111l-73.955 113.777z" _fill="#333"/><path pid="1" d="M227.556 603.022l39.822-34.133L489.244 762.31 705.422 568.89l39.822 34.133-256 227.556z" _fill="#333"/><path pid="2" d="M455.111 398.222H512V768h-56.889z" _fill="#333"/>'
  }
})
