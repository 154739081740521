/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'schoolManagement': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.09 5.077h3.41V21h-15V5.077h3.41v1.385h8.18V5.077zM7.228 12h9.546v-1.385H7.227V12zm0 5.538h9.546v-1.384H7.227v1.384zM9.273 5.077V3h5.454v2.077H9.273z" _fill="#333" fill-rule="evenodd"/>'
  }
})
