export const appTitle: string = "英语口语人机对话训练教考练平台";//"HYT英语口语人机对话训练教考平台";
export enum RoleId {
    unknown = "",
    examStudent = "examStudent",
    student = "student",
    teacher = "teacher",
    admin = "admin",
    schoolAdmin = "schoolAdmin",
    platformAdmin = "platformAdmin",
    contentAdmin = "contentAdmin",
    businessAdmin = "businessAdmin",
    user = "user",
    formalExamAdmin = "formalExamAdmin",
    mockExamAdmin = "mockExamAdmin"
}

export const RoleIds: Array<RoleId> = [RoleId.examStudent, RoleId.student, RoleId.teacher, RoleId.admin, RoleId.schoolAdmin,
RoleId.platformAdmin, RoleId.contentAdmin, RoleId.businessAdmin, RoleId.user, RoleId.formalExamAdmin, RoleId.mockExamAdmin];

export const roles: { roleId: RoleId, roleName: string, disabled: boolean }[] = [
    {
        roleId: RoleId.examStudent,
        roleName: "考生",
        disabled: true
    }, {
        roleId: RoleId.student,
        roleName: "学生",
        disabled: true
    }, {
        roleId: RoleId.teacher,
        roleName: "教师",
        disabled: false
    }, {
        roleId: RoleId.admin,
        roleName: "管理员",
        disabled: false
    }, {
        roleId: RoleId.schoolAdmin,
        roleName: "学校管理员",
        disabled: true
    }, {
        roleId: RoleId.platformAdmin,
        roleName: "平台管理员",
        disabled: false
    }, {
        roleId: RoleId.contentAdmin,
        roleName: "内容管理员",
        disabled: false
    }, {
        roleId: RoleId.businessAdmin,
        roleName: "商务管理员",
        disabled: true
    }, {
        roleId: RoleId.user,
        roleName: "用户",
        disabled: true
    }, {
        roleId: RoleId.formalExamAdmin,
        roleName: "考试管理员",
        disabled: false
    }, {
        roleId: RoleId.mockExamAdmin,
        roleName: "练习管理员",
        disabled: false
    }


]