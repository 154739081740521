import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { replace } from 'lodash'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
})

// Request interceptors
service.interceptors.request.use(
  config => {
    config.headers['Access-Control-Allow-Origin'] = "*";
    // Add X-Access-Token header to every request, you can add other custom headers here
    if (UserModule.token) {
      config.headers.Authorization = `bearer ${UserModule.token}`
    } else {
      // Base Auth
      /*
      config.auth = {
        username: 'super',
        password: func.backEndAuthDynamicPassword()
      }
      */
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    switch (response.status) {
      case 403: {
        console.log(`response, status: ${response.status}`);
        break;
      }
    }
    const res = response.data;
    // if (response.status !== 200) {
    if (res.code !== 0) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      if (res.code === 401) {
        MessageBox.confirm(
          'You have been logged out, try to login again.',
          'Log out',
          {
            confirmButtonText: 'Relogin',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          UserModule.ResetToken()
          location.reload() // To prevent bugs from vue-router
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
      // }
    } else {
      return response.data
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: {
          error.message = '请求错误(400)';
          break;
        }
        case 401: {
          error.message = '未授权，请重新登录(401)';
          break;
        }
        case 403: {
          error.message = '拒绝访问(403)';
          break;
        }
        case 404: {
          error.message = '请求出错(404)';
          break;
        }
        case 408: {
          error.message = '请求超时(408)';
          break;
        }
        case 500: {
          error.message = '服务器错误(500)';
          break;
        }
        case 501: {
          error.message = '服务未实现(501)';
          break;
        }
        case 502: {
          error.message = '网络错误(502)';
          break;
        }
        case 503: {
          error.message = '服务不可用(503)';
          break;
        }
        case 504: {
          error.message = '网络超时(504)';
          break;
        }
        case 505: {
          error.message = 'HTTP版本不受支持(505)';
          break;
        }
        default: { error.message = `连接出错(${error.response.status})!` };
      }
      let msg: string = "";//"身份验证失败，请检查用户/密码是否正确或已过期。";
      if (error.response.data) {
        msg = replace(error.response.data.message || error.response.data.reasonstring || msg, "[EMVCJWTException]", "");
      }
      if (msg) {
        error.message = msg;
      }

    } else {
      error.message = '连接服务器失败!'
    }

    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
