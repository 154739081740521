/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'nav_icon_tpl_n': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: ''
  }
})
