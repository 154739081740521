/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'student-count': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M504.29 576.68c-150.17 0-272.35-122.93-272.35-274.03C231.95 153.42 354.12 32 504.29 32s272.33 121.42 272.33 270.65c.01 151.1-122.16 274.03-272.33 274.03zm0-476.18c-112.4 0-203.84 90.69-203.84 202.15 0 113.33 91.45 205.53 203.84 205.53s203.83-92.19 203.83-205.53c0-111.46-91.43-202.15-203.83-202.15z"/><path pid="1" d="M410.8 988.38H96.13c-18.92 0-34.25-15.33-34.25-34.25 0-245.82 198.46-445.81 442.4-445.81 18.92 0 34.25 15.33 34.25 34.25s-15.33 34.25-34.25 34.25c-194.72 0-355.16 151-372.37 343.05H410.8c18.92 0 34.25 15.33 34.25 34.25s-15.33 34.26-34.25 34.26zM756.99 637.76c-97.82 0-177.12 79.3-177.12 177.12S659.17 992 756.99 992s177.12-79.3 177.12-177.12H756.99V637.76z"/><path pid="2" d="M785 609.76v177.12h177.12c0-97.82-79.3-177.12-177.12-177.12z"/>'
  }
})
