import Vue, { DirectiveOptions } from 'vue'
import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'
import '@/styles/element-variables.scss'
import '@/styles/index.scss'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import '@/icons/components'
import '@/permission'
import * as directives from '@/utils/el-draggable-dialog'
import dayjs from 'dayjs'

//const customLocale = window.dayjs_locale_zh_cn;
require("dayjs/locale/zh-cn");
dayjs.locale("zh-cn");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

Vue.use(ElementUI)

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
})

Vue.config.productionTip = false
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
