/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'nav_icon_library_n': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.572 15.023l.126.05 2.394 1.216a.75.75 0 01.117 1.266l-.117.072-9.123 4.64a2.125 2.125 0 01-1.728.092l-.207-.093-9.122-4.64a.75.75 0 01-.117-1.264l.117-.072 2.393-1.216a.75.75 0 01.795 1.264l-.116.072-1.078.548 7.807 3.972c.147.075.31.09.464.045l.114-.045 7.804-3.972-1.077-.548a.75.75 0 01-.379-.882l.05-.126a.75.75 0 01.883-.38zm.001-4.96l.126.052 2.393 1.218a.75.75 0 01.115 1.264l-.115.072-9.12 4.638a2.115 2.115 0 01-1.733.093l-.205-.093-9.122-4.638a.75.75 0 01-.117-1.264l.116-.072 2.392-1.218a.75.75 0 01.795 1.264l-.114.072-1.078.55 7.809 3.971c.142.075.308.091.462.045l.114-.046L20.095 12l-1.077-.55a.75.75 0 01-.378-.883l.051-.125a.75.75 0 01.882-.38zm-8.539-8.33a2.127 2.127 0 011.935 0l9.123 4.64a.75.75 0 010 1.337l-9.123 4.64c-.608.31-1.327.31-1.935 0L1.909 7.71a.75.75 0 010-1.336l9.125-4.641zm1.143 1.293a.624.624 0 00-.465.045L3.906 7.04l7.807 3.97c.15.076.311.09.465.046l.113-.045 7.807-3.97-7.807-3.971-.114-.045z" _fill="#999" fill-rule="nonzero"/>'
  }
})
