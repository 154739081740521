














































import { Component, Vue } from "vue-property-decorator";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";
import { AppModule } from "@/store/modules/app";

@Component({
  name: "AppMain",
  components: {
    Breadcrumb,
    Hamburger
  }
})
export default class extends Vue {
  /*缓存*/
  private tagsList: Array<string> = [
    "Dashboard",
    "ExamStudentListPage",
    "ExamProjectListPage",
    "SchoolListPage",
    "SchoolContractListPage",
    "ExamRoomListPage",
    "ClassRoomListPage",
    "ExamInfoTotalByExamSchoolPage",
    "SystemUserListPage",
    "TestPaperListPage",
    "TestPaperAuthListPage",
    "ResourceTemplateListPage",
    "MockExamAutoScoreListPage",
    "FormalExamAutoScoreListPage",
    "SetupFileListPage",
    "TestPaperPackageListPage",
    "ScoreProjectListPage",
    "FormalExamSchoolListPage",
    "SchoolTestPaperListPage"
  ];
  get sidebar() {
    return AppModule.sidebar;
  }
  private toggleSideBar() {
    AppModule.ToggleSideBar(true);
  }
}
