/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'testsList': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M896 896a128 128 0 01-128 128H128A128 128 0 010 896V256a128 128 0 01128-128A128 128 0 01256 0h640a128 128 0 01128 128v640a128 128 0 01-128 128zm-832 0a64 64 0 0064 64h640a64 64 0 0064-64V448H64v448zm0-640v128h768V256a64 64 0 00-64-64H128a64 64 0 00-64 64zm896-128a64 64 0 00-64-64H256a64 64 0 00-64 64h576a128 128 0 01128 128v576a64 64 0 0064-64V128zM160 256h384a32 32 0 010 64H160a32 32 0 010-64zm576 0a32 32 0 110 64 32 32 0 010-64z"/>'
  }
})
