/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'basic-config': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#d81e06" d="M355.135.287H97.145a89.977 89.977 0 00-96.8 96.8v257.99a89.55 89.55 0 0096.8 96.373h257.99a89.55 89.55 0 0096.8-96.373V97.087a90.403 90.403 0 00-96.8-96.8zm0 571.842H99.277A96.373 96.373 0 00.346 676.605v250.314a89.977 89.977 0 0096.8 96.8h257.99a98.079 98.079 0 0096.799-96.8v-257.99a89.977 89.977 0 00-96.8-96.8zM928.683.287H672.825a88.271 88.271 0 00-95.093 95.094v246.05a94.667 94.667 0 0095.52 102.77H929.11a92.962 92.962 0 0094.667-94.668V93.675A88.697 88.697 0 00928.683.287zm25.16 764.589a179.953 179.953 0 00-14.925-36.247l36.673-36.673-64.818-64.39-36.673 36.672a163.323 163.323 0 00-35.82-14.925V597.29h-91.682v52.024a163.323 163.323 0 00-35.82 14.925l-36.673-36.673-64.818 64.391 36.673 36.673a179.953 179.953 0 00-14.925 36.247h-52.024v91.256h52.024a133.046 133.046 0 006.397 18.336l6.823 14.499-36.673 36.673 64.817 64.39 36.673-36.672a153.088 153.088 0 0014.499 7.249 92.962 92.962 0 0019.189 6.823v56.289h91.682v-52.025a92.962 92.962 0 0019.19-6.823l14.498-7.25 36.673 36.674 64.818-64.391-31.13-37.526 6.823-14.498a133.046 133.046 0 006.397-18.337h51.598v-94.667h-49.466zM792.226 924.36a113.857 113.857 0 11113.857-113.856A113.43 113.43 0 01792.226 924.36z"/>'
  }
})
