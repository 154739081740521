/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'school': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M509.867 307.098H289.353a25.839 25.839 0 00-22.383 12.928 25.865 25.865 0 000 25.856 25.839 25.839 0 0022.383 12.923h220.514a25.839 25.839 0 0022.383-12.928 25.865 25.865 0 000-25.856 25.839 25.839 0 00-22.383-12.923zm0 155.119H289.353a25.839 25.839 0 00-22.383 12.928 25.865 25.865 0 000 25.856 25.839 25.839 0 0022.383 12.923h220.514a25.835 25.835 0 0023.057-12.68 25.865 25.865 0 000-26.326 25.835 25.835 0 00-23.057-12.672v-.03zm358.421-51.704c-.956-29.483-25.596-52.62-55.066-51.708H647.616V203.686c-.951-29.482-25.596-52.62-55.061-51.707H206.74a53.367 53.367 0 00-38.34 14.383 53.423 53.423 0 00-16.879 37.324v672.18h-27.545a25.839 25.839 0 00-22.38 12.928 25.865 25.865 0 000 25.856 25.839 25.839 0 0022.38 12.923h771.78a25.839 25.839 0 0022.378-12.928 25.865 25.865 0 000-25.856 25.839 25.839 0 00-22.378-12.923h-27.52l.051-465.353zM592.631 875.861h-385.89V225.997a23.262 23.262 0 0123.825-22.362H568.73a23.262 23.262 0 0123.825 22.362v650l.076-.127zm220.438 0H647.693V433.08a23.262 23.262 0 0123.825-22.357h117.7a23.262 23.262 0 0123.825 22.357v443.047l.026-.256zM509.867 617.387H289.353a25.839 25.839 0 00-22.383 12.923 25.865 25.865 0 000 25.856 25.839 25.839 0 0022.383 12.924h220.514a25.839 25.839 0 0022.383-12.928 25.865 25.865 0 000-25.856 25.839 25.839 0 00-22.383-12.924z"/>'
  }
})
