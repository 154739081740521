






import { Component, Vue } from "vue-property-decorator";
import DevicePixelRatio from './utils/devicePixelRatio';
@Component({
  name: "App",

})
export default class extends Vue {

  created() {
    //new DevicePixelRatio().init();
  }

  mounted() {
  }

}
