/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#d81e06" d="M867.3 882.3h-64V465c0-77.5-30.4-150.5-85.6-205.7-55.2-55.2-128.3-85.6-205.7-85.6s-150.5 30.4-205.7 85.6c-55.2 55.2-85.6 128.3-85.6 205.7v417.3h-64V465c0-47.8 9.4-94.2 28.1-138 18-42.3 43.6-80.3 76.3-112.9 32.7-32.7 70.7-58.3 112.9-76.3 43.8-18.6 90.3-28.1 138-28.1 47.8 0 94.2 9.4 138 28.1 42.3 18 80.3 43.6 112.9 76.3 32.7 32.7 58.3 70.7 76.3 112.9 18.6 43.8 28.1 90.3 28.1 138v417.3zm55.9 3.9H100.8c-17.7 0-32-14.3-32-32s14.3-32 32-32h822.4c17.7 0 32 14.3 32 32s-14.3 32-32 32zM512 625.5c-17.7 0-32-14.3-32-32V330.7c0-17.7 14.3-32 32-32s32 14.3 32 32v262.8c0 17.6-14.3 32-32 32zm0 99.2c-17.7 0-32-14.3-32-32V688c0-17.7 14.3-32 32-32s32 14.3 32 32v4.7c0 17.7-14.3 32-32 32z"/>'
  }
})
