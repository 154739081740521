


































import { Component, Vue, Prop } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import { changePassword, publicChangePassword } from "@/api/users";
import * as typ from "@/public/base_types";
interface formType {
  password: string;
  newPassword: string;
  trueNewPassword: string;
  username: string;
  userId: string;
}
@Component({
  name: "passWord",
})
export default class extends Vue {
  @Prop({ default: 'private' }) private type!: string
  private ruleForm: formType = {
    password: "",
    newPassword: "",
    trueNewPassword: "",
    username: "",
    userId: "",
  };
  private validateUsername = (rule: any, value: string, callback: Function) => {
    if (this.type == "public" && !/^[a-zA-Z0-9_-]{4,16}$/.test(value)) {
      callback(new Error("用户名不符合规则"));
    } else {
      callback();
    }
  };
  private validatePassword = (rule: any, value: string, callback: Function) => {
    if (value.length < typ.PSD_MIN_LEN) {
      callback(new Error(`密码长度不能少于${typ.PSD_MIN_LEN}个字符`));
    } else if (value.length > typ.PSD_MAX_LEN) {
      callback(new Error(`密码长度不能超过${typ.PSD_MAX_LEN}个字符`));
    } else if (
      !/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(
        value
      )
    ) {
      callback(new Error("密码不符合安全规则,需包含大小写字母、数字及特殊符号：!@#$%^&*?"));
    } else {
      callback();
    }
  };

  private rules: any = {
    username: [
      { validator: this.validateUsername, trigger: "blur" },
    ],
    password: [
      { required: true, message: "请输入密码", trigger: "blur" },
      /*{ validator: this.validatePassword, trigger: "blur" },*/
    ],
    newPassword: [
      { required: true, message: "请输入确认密码", trigger: "blur" },
      { validator: this.validatePassword, trigger: "blur" },
    ],
    trueNewPassword: [
      { required: true, message: "请输入确认密码", trigger: "blur" },
    ],
  };

  private async updatePwd() {
    //修改密码
    switch (this.type) {
      case "public": {
        this.ruleForm.userId = "";
        await publicChangePassword(this.ruleForm);
        break;
      }
      default: {
        this.ruleForm.username = "";
        this.ruleForm.userId = UserModule.userId;
        await changePassword(this.ruleForm);
      }
    }
    AppModule.UpdatePassWord(false);
    this.$message({ type: "success", message: "修改成功!" });
  }

  submitForm(formName: string | number) {
    (this.$refs[formName] as any).validate((valid: boolean) => {
      if (valid) {
        if (this.ruleForm.newPassword !== this.ruleForm.trueNewPassword)
          return this.$message({
            type: "warning",
            message: "确认密码不一致!",
          });
        //
        this.updatePwd();
      } else {
        return false;
      }
    });
  }
}
