import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
/* Layout */
import Layout from "@/layout/index.vue";
Vue.use(Router);


export const constantRoutes: RouteConfig[] = [
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
    meta: { hidden: true, title: '登陆' }
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    meta: { hidden: true }
  },
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export const asyncRoutes: RouteConfig[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/Dashboard',
    children: [
      {
        path: '/Dashboard',
        /*
        redirect: () => {
          return '/statisticsManagement'
        },
        */
        component: () => import('@/views/dashboard/index2.vue'),
        meta: {
          title: '首页',
          icon: 'dashboard',

        }
      }
    ]
  },
  {
    path: '/Dashboard2',
    component: Layout,
    meta: {
      title: '数据看板',
      icon: 'dashboard2',
      alwaysShow: true
    },
    children: [
      {
        path: 'admin',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '超级管理',
          icon: 'dashboard2',
          roles: ['admin']
        }
      },
      {
        path: 'formalExamAdmin',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '考试管理',
          icon: 'dashboard2',
          roles: ['formalExamAdmin']
        }
      },
      {
        path: 'mockExamAdmin',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '练习管理',
          icon: 'dashboard2',
          roles: ['mockExamAdmin']
        }
      },
      {
        path: 'platformAdmin',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '平台管理',
          icon: 'dashboard2',
          roles: ['platformAdmin']
        }
      },
      {
        path: 'contentAdmin',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '内容管理',
          icon: 'dashboard2',
          roles: ['contentAdmin']
        }
      },
      {
        path: 'teacher',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          title: '老师看板',
          icon: 'dashboard2',
          roles: ['teacher']
        }
      }
    ]
  },
  {
    path: '/examManagement',
    component: Layout,
    meta: {
      title: '考试管理',
      icon: 'examManagement',
      alwaysShow: false
    },
    children: [
      {
        path: 'examProjectList',
        component: () =>
          import('@/views/exam-management/exam-project-list.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '考试项目',
          icon: 'testsList',

        }
      },
      {
        path: 'examStudentList',
        component: () =>
          import('@/views/exam-management/exam-student-list.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '考生列表',
          icon: 'examManagement',

        }
      },
      {
        path: `examSchoolList`,
        component: () =>
          import('@/views/statistics-management/formal-exam-school-list.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '考点统计',
          icon: 'score',

        }
      },

      {
        path: `examSchoolStatisticsDetails`,
        component: () =>
          import('@/views/statistics-management/exam-school-statistics-details.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '考点统计详情',
          icon: 'score',
          hidden: true
        }
      },
      {
        path: `examAutoScoreList`,
        component: () =>
          import('@/views/statistics-management/formal-exam-auto-score-list.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '场次统计',
          icon: 'score',

        }
      },
      {
        path: 'examStudentScoreList',
        component: () =>
          import('@/views/statistics-management/exam-student-score-list.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '考生成绩',
          icon: 'score',

        }
      },
      {
        path: 'newExamStudent',
        component: () =>
          import('@/views/exam-management/exam-student-editor.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '新增考生',
          icon: 'user',
          hidden: true,

        }
      },
      {
        path: 'editExamStudent',
        component: () =>
          import('@/views/exam-management/exam-student-editor.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '编辑考生',
          icon: 'user',
          hidden: true,

        }
      },
      {
        path: 'newExamProject',
        component: () =>
          import('@/views/exam-management/exam-project-editor.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '新建考试项目',
          icon: 'user',
          hidden: true,

        }
      },
      {
        path: 'editExamProject',
        component: () =>
          import('@/views/exam-management/exam-project-editor.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '编辑考试项目',
          icon: 'user',
          hidden: true,

        }
      },
      {
        path: 'viewExamProject',
        component: () =>
          import('@/views/exam-management/exam-project-profile.vue'),
        meta: {
          roles: ['admin', 'formalExamAdmin'],
          title: '查看考试项目',
          icon: 'user',
          hidden: true,

        }
      }
    ]
  },
  {
    path: '/mockExamManagement',
    component: Layout,
    meta: {
      title: '练习管理',
      icon: 'examManagement',
      alwaysShow: false
    },
    children: [
      {
        path: `examStudentTemplateList`,
        component: () =>
          import('@/views/mock-exam-project/exam-student-template-list.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'teacher'],
          title: '学生列表',
          icon: 'examManagement',

        }
      },
      {
        path: `examProjectList`,
        component: () =>
          import('@/views/mock-exam-project/exam-project-list.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'teacher'],
          title: '练习项目',
          icon: 'testsList',

        }
      },
      {
        path: `examAutoScoreList`,
        component: () =>
          import('@/views/statistics-management/mock-exam-auto-score-list.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'teacher'],
          title: '自动评分',
          icon: 'score',

        }
      },
      {
        path: 'examStudentScoreList',
        component: () =>
          import('@/views/statistics-management/exam-student-score-list.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'teacher'],
          title: '考生成绩',
          icon: 'score',

        }
      }
    ]
  },
  {
    path: '/schoolManagement',
    component: Layout,
    meta: {
      title: '学校管理',
      icon: 'school',
      alwaysShow: false
    },
    children: [
      {
        path: 'schoolList',
        component: () => import('@/views/school-management/school-list.vue'),
        meta: {
          roles: ['admin', 'platformAdmin', 'teacher'],
          title: '学校列表',
          icon: 'school',

        }
      },
      {
        path: 'createSchool',
        component: () =>
          import('@/views/school-management/school-editor.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '创建学校',
          icon: 'school',
          hidden: true,

        }
      },
      {
        path: 'schoolContractList',
        component: () => import('@/views/school-management/school-contract-list.vue'),
        meta: {
          roles: ['admin'],
          title: '合约列表',
          icon: 'school',

        }
      },
      {
        path: 'examRoomList',
        component: () =>
          import('@/views/school-management/exam-room-list.vue'),
        meta: {
          roles: ['admin', 'platformAdmin', 'mockExamAdmin', 'formalExamAdmin', 'teacher'],
          title: '考场列表',
          icon: 'examRoom',

        }
      },
      {
        path: 'newExamRoom',
        component: () =>
          import('@/views/school-management/exam-room-editor.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '新增考场',
          icon: 'examRoom',
          hidden: true,

        }
      },
      {
        path: 'editExamRoom',
        component: () =>
          import('@/views/school-management/exam-room-editor.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '编辑考场',
          icon: 'examRoom',
          hidden: true,

        }
      },
      {
        path: 'classRoomList',
        component: () =>
          import('@/views/school-management/class-room-list.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '教室列表',
          icon: 'examRoom',

        }
      },
      {
        path: 'newClassRoom',
        component: () =>
          import('@/views/school-management/class-room-editor.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '新增教室',
          icon: 'examRoom',
          hidden: true,

        }
      },
      {
        path: 'editClassRoom',
        component: () =>
          import('@/views/school-management/class-room-editor.vue'),
        meta: {
          roles: ['admin', 'platformAdmin'],
          title: '编辑教室',
          icon: 'examRoom',
          hidden: true,

        }
      }
    ]
  },
  {
    path: '/test',
    component: Layout,
    meta: {
      title: '资源管理',
      icon: 'testPaper',
      alwaysShow: false
    },
    children: [

      {
        path: 'testPaperPackageList',
        component: () => import('@/views/test-management/test-paper-package-list.vue'),
        meta: {
          roles: ['admin', 'contentAdmin', 'teacher'],
          title: '试卷包',
          icon: 'testPaper',

        }
      },
      {
        path: 'testList',
        component: () => import('@/views/test-management/test-list.vue'),
        meta: {
          roles: ['admin', 'contentAdmin', 'teacher'],
          title: '试卷库',
          icon: 'testPaper',

        }
      },
      {
        path: 'testEditor',
        component: () => import('@/views/test-management/test-editor.vue'),
        meta: {
          roles: ['admin', 'contentAdmin'],
          title: '新增试卷',
          icon: 'testPaper',
          hidden: true,

        }
      },
      {
        path: 'testAnswer',
        component: () => import('@/views/test-management/test-answer.vue'),
        meta: {
          roles: ['admin', 'contentAdmin'],
          title: '标答',
          icon: 'testPaper',
          hidden: true,

        }
      },
      {
        path: 'schoolTestPaperUseList',
        component: () => import('@/views/test-management/school-test-paper-list.vue'),
        meta: {
          roles: ['admin'],
          title: '已授权',
          icon: 'testPaper',

        }
      },
      /*
      {
        path: 'testPaperUseAuthList',
        component: () => import('@/views/test-management/test-paper-auth-list.vue'),
        meta: {
          roles: ['admin'],
          title: '试卷授权管理',
          icon: 'testPaper',

        }
      },*/
      {
        path: 'testPaperUseAuth',
        component: () => import('@/views/test-management/test-paper-auth.vue'),
        meta: {
          roles: ['admin', 'contentAdmin'],
          title: '使用授权',
          icon: 'testPaper',
          hidden: true,

        }
      },
      {
        path: 'resourceTemplateList',
        component: () => import('@/views/test-management/resource-template-list.vue'),
        meta: {
          roles: ['admin', 'contentAdmin'],
          title: '资源模板',
          icon: 'testPaper',

        }
      },
      {
        path: 'resourceTemplateEditor',
        component: () => import('@/views/test-management/resource-template-editor.vue'),
        meta: {
          roles: ['admin', 'contentAdmin'],
          title: '新增资源模板',
          icon: 'testPaper',
          hidden: true,

        }
      },
      //
    ]
  },
  {
    path: '/statisticsManagement',
    component: Layout,
    redirect: '/statisticsManagement/examInfoTotalByExamSchool',
    meta: {
      title: '统计报表',
      icon: 'statisticsManagement',
      alwaysShow: false
    },
    children: [
      {
        path: 'examInfoTotalByExamSchool',
        component: () =>
          import('@/views/statistics-management/exam-info-by-school.vue'),
        meta: {
          roles: ['admin'],
          title: '考点信息统计', // 考场信息
          icon: 'statisticsManagement', // el-icon-s-platform

        }
      },
      {
        path: 'statisticsByExamSchool',
        component: () =>
          import('@/views/statistics-management/statistics-by-exam-school.vue'),
        meta: {
          roles: ['admin'],
          title: '练习统计与分析', // 练习统计与分析
          icon: 'statisticsManagement', // el-icon-s-platform

        }
      }
    ]
  },
  {
    path: '/scoreReportPlatform',
    component: Layout,
    redirect: '/scoreReportPlatform/socreProject',
    meta: {
      title: '统分平台',
      icon: 'statisticsManagement',
      alwaysShow: false
    },
    children: [
      {
        path: 'scoreProject',
        component: () =>
          import('@/views/score-report-platform/score-project-list.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'formalExamAdmin'],
          title: '统分项目', // 考场信息
          icon: 'statisticsManagement', // el-icon-s-platform
        }
      },
      {
        path: 'scoreProjectEditor',
        component: () => import('@/views/score-report-platform/score-project-editor.vue'),
        meta: {
          roles: ['admin', 'mockExamAdmin', 'formalExamAdmin', 'teacher'],
          title: '新增统分项目',
          icon: 'statisticsManagement',
          hidden: true,

        }
      },
    ]
  },
  {
    path: '/system',
    component: Layout,
    meta: {
      title: '系统管理',
      icon: 'system',
      alwaysShow: false
    },
    children: [
      {
        path: 'systemUser',
        component: () => import('@/views/system-user/user-list.vue'),
        meta: {
          roles: ['admin'],
          title: '用户管理',
          icon: 'system-user',

        }
      },
      {
        path: 'systemUserEditor',
        component: () => import('@/views/system-user/user-list-editor.vue'),
        meta: {
          roles: ['admin'],
          title: '新增用户',
          icon: 'system-user',
          hidden: true,

        }
      },
      {
        path: 'userSchoolAuth',
        component: () => import('@/views/system-user/user-school-auth.vue'),
        meta: {
          roles: ['admin'],
          title: '学校授权',
          icon: 'school',
          hidden: true,

        }
      },
      {
        path: 'setupFile',
        component: () => import('@/views/system-upgrade/setup-file-list.vue'),
        meta: {
          roles: ['admin'],
          title: '升级记录',
          icon: 'system',

        }
      },
      {
        path: 'installFile',
        component: () => import('@/views/system-upgrade/install-file-list.vue'),
        meta: {
          roles: [],
          title: '安装文件',
          icon: 'setup',

        }
      },
      {
        path: 'setupFileEditor',
        component: () => import('@/views/system-upgrade/setup-file-editor.vue'),
        meta: {
          roles: ['admin'],
          title: '新增升级',
          icon: 'system',
          hidden: true,

        }
      },
      {
        path: 'systemManage',
        component: () => import('@/views/system-manage/index.vue'),
        meta: {
          roles: [],
          title: '系统管理',
          icon: 'management',

        }
      },
    ]
  },
  {
    path: '*',
    redirect: '/404',
    meta: { hidden: true }
  }
]

const createRouter = () => new Router({
  // mode: "history",  // Disabled due to Github Pages doesn"t support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
