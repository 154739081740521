/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'equipment': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#d81e06" d="M885.312 773.312H138.688C90.432 773.312 64 745.6 64 698.752v-485.44c0-46.848 26.432-74.688 74.688-74.688h746.688c48.192.064 74.624 27.904 74.624 74.688v485.376c0 46.912-26.432 74.624-74.688 74.624zm0-560H138.688v485.312h746.688V213.312zm-412.928 376.96l-26.368-26.432-105.6-105.6 52.8-52.8 79.168 79.168 158.4-158.4 52.8 52.8L498.816 563.84l-26.432 26.432zM307.2 828.736h83.2l9.6-18.048h224l9.6 18.048h83.2c16.128 0 29.184 12.672 29.184 28.288s-13.056 28.288-29.184 28.288H307.2c-16.128 0-29.12-12.672-29.12-28.288s12.992-28.288 29.12-28.288z"/>'
  }
})
