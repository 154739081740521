/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'score': {
    width: 32,
    height: 32,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M849 47c49.706 0 90 40.294 90 90v749c0 49.706-40.294 90-90 90H172c-49.706 0-90-40.294-90-90V137c0-49.706 40.294-90 90-90h677zm-35 60H207c-32.259 0-58.471 25.89-58.992 58.024L148 166v693c0 32.259 25.89 58.471 58.024 58.992L207 918h607c32.259 0 58.471-25.89 58.992-58.024L873 859V166c0-32.585-26.415-59-59-59zm-74.5 644c15.188 0 27.5 12.312 27.5 27.5S754.688 806 739.5 806h-457c-15.188 0-27.5-12.312-27.5-27.5s12.312-27.5 27.5-27.5h457zm0-122c15.188 0 27.5 12.312 27.5 27.5S754.688 684 739.5 684h-457c-15.188 0-27.5-12.312-27.5-27.5s12.312-27.5 27.5-27.5h457zM408.113 227.21L518.49 514.751c3.958 10.312-1.192 21.88-11.504 25.84-10.312 3.958-21.88-1.193-25.84-11.505L449.637 447H325.019l-31.633 82.408c-3.916 10.202-15.281 15.352-25.51 11.627l-.329-.123c-10.312-3.958-15.462-15.527-11.504-25.839L366.42 227.53c3.145-8.19 11.09-13.125 19.393-12.825 9.296-1.725 18.79 3.36 22.3 12.504zM739.5 475c15.188 0 27.5 12.312 27.5 27.5S754.688 530 739.5 530h-158c-15.188 0-27.5-12.312-27.5-27.5s12.312-27.5 27.5-27.5h158zM655 222c12.15 0 22 9.85 22 22v56h46c12.15 0 22 9.85 22 22s-9.85 22-22 22h-46v46c0 12.15-9.85 22-22 22s-22-9.85-22-22v-46h-57c-12.15 0-22-9.85-22-22s9.85-22 22-22h57v-56c0-12.15 9.85-22 22-22zm-267.672 62.68L340.373 407h93.909l-46.954-122.32z" _fill="#333"/>'
  }
})
