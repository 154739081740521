/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'system-settings': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#d81e06" d="M852.5 881.1H172.3c-.7 0-1.2-.5-1.2-1.2v-66.6c0-.7.5-1.2 1.2-1.2h680.2c.7 0 1.2.5 1.2 1.2V880c0 .6-.6 1.1-1.2 1.1zM540.1 426.8c-30.4-15.3-67.4-3.1-82.7 27.1-15.3 30.2-3.1 67.1 27.2 82.4 30.4 15.3 67.4 3.1 82.7-27.1s3.2-67.1-27.2-82.4zm350.8-283.2H133.8c-38.2 0-69.2 30.9-69.2 69V708c0 38.1 31 69 69.2 69h757.1c38.2 0 69.2-30.9 69.2-69V212.6c.1-38.1-30.9-69-69.2-69zM657.4 522.1c6.1 9.4 14.2 16.8 23.5 21.9-2.3 6.1-5 12.2-8 18.2-6.9 13.7-15.4 26-25 37.1-1.6-1-3.3-2-5.1-2.9-34.2-17.2-75.8-3.6-93.1 30.5-5 9.9-8 21.8-7.9 32.3l-.4.1c-21.1 3.7-42 2.2-63.6-2.1.1-1.9.1-3.8 0-5.8-2.2-38.1-34.9-67.2-73.1-65.1-11.3.6-21.8 3.9-30.9 9.2-13.9-16.8-24.5-35.8-31.4-56.1 1.7-.9 3.3-1.9 5-2.9 32-20.9 41-63.7 20-95.6-6.1-9.4-14.1-16.8-23.4-22 2.3-6.1 5-12.2 8-18.2 6.9-13.7 15.4-26 25-37l.2.1c1.6 1 3.1 1.9 4.9 2.8 34.2 17.2 75.9 3.5 93.1-30.5 5.1-10 7.5-20.6 7.4-31.2h.2c21.1-3.6 42.8-3.5 64.3.9-.1 1.9-.1 3.8 0 5.8 2.2 38 34.9 67.2 73.1 65 11.2-.6 21.2-3.9 30.2-9.1h.1c14 16.6 25 35.8 32 56.1-1.7.9-3.4 1.9-5 2.9-32.1 20.9-41.1 63.8-20.1 95.6z"/>'
  }
})
