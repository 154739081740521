



















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { DeviceType, AppModule } from "@/store/modules/app";
import { AppMain, Navbar, Sidebar } from "./components";
import ResizeMixin from "./mixin/resize";

@Component({
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    Sidebar
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    };
  }

  private handleClickOutside() {
    AppModule.CloseSideBar(false);
  }
}
