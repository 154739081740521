








































































import { Component, Vue } from "vue-property-decorator";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";
import Breadcrumb from "@/components/Breadcrumb/index.vue";
import Hamburger from "@/components/Hamburger/index.vue";
import updatePassWord from "@/components/password/password.vue";
import { appTitle } from "@/utils/public-consts";

@Component({
  name: "Navbar",
  components: {
    Breadcrumb,
    Hamburger,
    updatePassWord,
  },
})
export default class extends Vue {
  get sidebar() {
    return AppModule.sidebar;
  }

  get device() {
    return AppModule.device.toString();
  }

  private get title(): string {
    return appTitle;
  }

  private get avatar(): string {
    return UserModule.avatar;
  }

  private logoUrl(): string {
    return `${window.location.protocol}//${window.location.host}/assets/custom/images/logo.png`;
  }

  private defaultLogoUrl(): string {
    return require(`@/assets/images/logo.png`);
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(true);
  }

  updatePassWord() {
    AppModule.UpdatePassWord(true);
  }
  private async logout() {
    await UserModule.LogOut();
    location.reload();
    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
  }
}
