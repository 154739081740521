/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'management': {
    width: 16,
    height: 16,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M192 384c0 35.2 28.8 64 64 64s64-28.8 64-64-28.8-64-64-64-64 28.8-64 64zm64 128c-35.2 0-64 28.8-64 64s28.8 64 64 64 64-28.8 64-64-28.8-64-64-64zm0 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm0 96c-35.2 0-64 28.8-64 64s28.8 64 64 64 64-28.8 64-64-28.8-64-64-64zm0 96c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128-416c0-17.6 14.4-30.4 32-30.4l384 1.6c17.6 0 32 11.2 32 28.8s-14.4 32-32 32H416c-17.6 0-32-14.4-32-32zm0 192c0-17.6 14.4-32 32-32h384c17.6 0 32 14.4 32 32s-14.4 32-32 32H416c-17.6 0-32-14.4-32-32zm0 192c0-17.6 14.4-30.4 32-30.4l257.6-1.6c17.6 0 30.4 11.2 30.4 28.8s-14.4 32-32 32H416c-17.6 0-32-14.4-32-32zM265.6 128h1.6c0 35.2 30.4 64 67.2 64h334.4c36.8 0 67.2-28.8 67.2-64h1.6V64H736c0-35.2-30.4-64-67.2-64H334.4c-36.8 0-67.2 28.8-67.2 64h-1.6v64zM336 64h336v64H336V64zm624 0H720v64h240v832H62.4V126.4L304 128V64H64C28.8 64 0 92.8 0 128v832c0 33.6 28.8 62.4 64 62.4h896c35.2 0 64-28.8 64-62.4V128c0-33.6-28.8-64-64-64z"/>'
  }
})
