/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exam-answer-package': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M572.689 180.14H271.172V59.724h60.689q0-25.046 17.34-42.385T391.585 0h60.689q25.046 0 42.385 17.34T512 59.725h60.689V180.14zm-150.277-53.946q15.413 0 26.491-11.078t11.078-26.491q0-16.377-11.078-26.973t-26.491-10.596q-16.376 0-26.973 10.596t-10.596 26.973q0 15.413 10.596 26.49t26.973 11.079zm241.791 473.949q43.35 0 81.882 16.858t67.431 45.757 45.758 67.432 16.858 81.881q0 44.313-16.858 82.845t-45.758 67.432-67.431 45.276T664.203 1024q-44.312 0-82.845-16.376t-67.431-45.276-45.758-67.432-16.858-82.845q0-43.349 16.858-81.881t45.758-67.432 67.431-45.757 82.845-16.858zm131.01 193.626q5.78-6.744 10.597-16.377t-2.89-17.34q-1.927-1.926-9.151-9.632t-9.152-8.67q-9.633-6.743-18.303-1.927t-17.34 13.487q-22.156 22.156-43.83 43.349t-40.94 40.459q-6.744 6.743-14.932 8.188t-13.005-5.298q-2.89-2.89-9.151-9.634t-13.005-14.45-13.005-14.93-9.151-10.115q-5.78-4.817-13.005-6.262t-14.931 5.298q-.963.964-4.817 4.335t-7.706 7.707-7.707 7.706-4.816 4.335q-14.45 15.413 3.853 35.643 5.78 6.743 15.413 16.858t20.23 21.674 21.192 22.638 18.303 17.821q7.707 7.707 21.193 7.225t20.23-8.188q13.486-12.523 30.826-30.344t36.124-36.125 36.606-36.124 32.27-31.307zm-406.517-10.597q0 6.743-.482 13.487t-.481 14.45q0 43.348 12.523 82.362t35.642 71.767H154.611q-18.302 0-39.977-10.596t-40.459-27.936-31.308-39.496-12.523-46.24V244.682q0-20.23 9.633-41.904T65.024 162.8t35.642-30.345 42.386-12.041h68.395v120.414h420.967V120.414h59.725q22.156 0 43.83 9.633t39.015 26.01 28.418 39.495 11.078 49.13v341.011q-33.716-22.156-72.249-34.679t-81.881-12.523h-6.743q17.34-6.743 28.417-22.156t11.079-34.68q0-25.045-17.822-42.385t-42.867-17.34H211.447q-25.046 0-42.868 17.34t-17.82 42.386 17.82 42.867 42.868 17.821h403.627q-57.798 9.633-105 40.941t-78.029 79.473H211.447q-25.046 0-42.868 17.34t-17.82 42.386 17.82 42.867 42.868 17.821h177.25z"/>'
  }
})
