/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#d81e06" d="M836.71 910.9H172.39c-31.283 0-56.678-25.447-56.678-56.68V362.292c0-19.865 10.598-38.502 27.648-48.691l337.971-201.574a56.525 56.525 0 0158.829.46l326.298 201.216c16.64 10.24 26.93 28.724 26.93 48.282v492.237c0 31.232-25.446 56.678-56.678 56.678zM510.413 144.997c-2.765 0-5.581.717-8.09 2.253L164.352 348.774c-4.71 2.816-7.68 7.988-7.68 13.517V854.17c0 8.704 7.066 15.718 15.718 15.718h664.27c8.703 0 15.718-7.066 15.718-15.718V361.933c0-5.427-2.868-10.547-7.476-13.415L518.605 147.302a15.616 15.616 0 00-8.192-2.304z"/><path pid="1" _fill="#d81e06" d="M671.027 738.304H350.413c-11.315 0-20.48-9.165-20.48-20.48s9.165-20.48 20.48-20.48h320.563c11.315 0 20.48 9.165 20.48 20.48s-9.165 20.48-20.429 20.48z"/>'
  }
})
