import request from '@/utils/request'

export const login = async (data: any) =>
  await request({
    // 登录
    url: '/login',
    method: 'POST',
    headers: {
      "jwtusername": data.username,
      "jwtpassword": data.password,
      "Content-Type": "application/json"
    },
    data
  })

export const getUserInfo = () =>
  request({
    // 获取用户信息
    url: '/getUserInfo',
    method: 'POST'
  })

export const logout = () =>
  request({
    // 登出
    url: '/public/logout',
    method: 'GET'
  })

export const publicChangePassword = (data: {
  username: string,
  password: string,
  newPassword: string,
  trueNewPassword: string
}) =>
  request({
    // 修改密码
    url: '/public/changePassword',
    method: 'POST',
    data
  })



export const changePassword = (data: {
  userId: string,
  password: string,
  newPassword: string,
  trueNewPassword: string
}) =>
  request({
    // 修改密码
    url: '/changePassword',
    method: 'POST',
    data
  })

export const upgradePassword = (data: {}) => request({
  // 升级密码
  url: '/upgradePassword',
  method: 'POST',
  data
})

export const resetPassword = (data: {
  userId: string,
}) =>
  request({
    // 重置密码
    url: '/resetPassword',
    method: 'POST',
    data
  })

export const getUserList = (params: {
  pageSize: number,
  curPage: number,
  userIds?: string[],
  mergeAuthSchools?: boolean,
  keywords?: string
}) => request({
  // 获取用户列表
  url: '/userList',
  method: 'GET',
  params
})

export const delUser = (userId: string) => request({
  // 删除用户
  url: `/user/${userId}`,
  method: 'DELETE',
})

export const Adduser = (data: any) => request({
  // 添加用户
  url: `/user`,
  method: 'POST',
  data
})

export const getUser = (userId: string) => request({
  // 查询用户
  url: `/user/${userId}`,
  method: 'GET',
})

/**
 * 获取学校授权列表
 * @param data 
 * @returns 
 */
export const getUserSchoolAuthList = (params: {
  pageSize: number,
  curPage: number,
  userId?: string,
  userIds?: string[],
  schoolId?: string
}) =>
  request({
    url: '/v1/UserSchoolAuthList',
    method: 'GET',
    params
  })

/**
 * 获取学校授权
 * @param userSchoolAuthId 
 * @returns 
 */
export const getUserSchoolAuth = (userSchoolAuthId: string) =>
  request({
    url: `/v1/UserSchoolAuth/${userSchoolAuthId}`,
    method: 'GET',
  })
/**
 * 删除学校授权
 * @param userSchoolAuthId 
 * @returns 
 */
export const delUserSchoolAuth = (userSchoolAuthId: string) =>
  request({
    url: `/v1/UserSchoolAuth/${userSchoolAuthId}`,
    method: 'DELETE'
  })

/**
 * 保存学校授权
 * @param data 
 * @returns 
 */
export const postUserSchoolAuth = (data: any) =>
  request({
    url: '/v1/UserSchoolAuth',
    method: 'POST',
    data
  })