/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'testPaper': {
    width: 33.656,
    height: 32,
    viewBox: '0 0 1077 1024',
    data: '<defs/><path pid="0" d="M333.096 218.274a39.128 39.128 0 11-78.228.054 39.128 39.128 0 0178.228-.054zm0 143.44a39.074 39.074 0 11-78.282 0 39.074 39.074 0 0178.282 0zm-78.228 143.468a39.1 39.1 0 0078.228 0 39.1 39.1 0 00-78.228 0c0 21.585 0 0 0 0zM686.835 253.63H424.367a35.328 35.328 0 010-70.656h262.468a35.301 35.301 0 110 70.656zm0 143.44H424.367a35.355 35.355 0 010-70.682h262.468a35.301 35.301 0 010 70.682zm0 143.441H424.367a35.328 35.328 0 010-70.656h262.468a35.301 35.301 0 110 70.656zm-431.967 99.22a39.1 39.1 0 0078.228 0 39.1 39.1 0 00-78.228 0c0 21.558 0 0 0 0zm431.967 35.302H424.367a35.328 35.328 0 010-70.63h262.468a35.301 35.301 0 110 70.657z"/><path pid="1" d="M1056.606 199.68c0-127.596-47.104-174.727-105.256-174.727h-716.1A108.625 108.625 0 00126.76 133.47v682.173H21.261c0 159.313 58.8 183.404 131.396 183.404H780.8A108.598 108.598 0 00889.344 890.53V199.68h167.29zM845.905 890.53a65.159 65.159 0 01-65.105 65.078c-58.099 0-87.848-12.396-87.848-139.992h-522.78V133.443a65.132 65.132 0 0165.079-65.078H874.55c-17.193 27.271-27.918 68.555-28.403 127.462a38.265 38.265 0 00-.27 3.853v690.823h.028z"/>'
  }
})
