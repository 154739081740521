/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'dashboard2': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M112 880c0-17.673 14.327-32 32-32h736c17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32H144c-17.673 0-32-14.327-32-32zm464-768H144c-17.673 0-32 14.327-32 32v592c0 17.673 14.327 32 32 32h736c17.673 0 32-14.327 32-32V384h-64v320H176V176h400v-64z"/><path pid="1" d="M275 523.833l218.892-218.891c12.497-12.497 32.758-12.497 45.255 0l22.627 22.627-241.519 241.519L275 523.833z"/><path pid="2" d="M627 437.314c-12.496 12.497-32.758 12.497-45.254 0l-87.118-87.118c-12.497-12.497-12.497-32.758 0-45.255 12.497-12.496 32.758-12.496 45.255 0L627 392.059c12.497 12.497 12.497 32.758 0 45.255z"/><path pid="3" d="M854.314 166.627c12.497 12.497 12.497 32.759 0 45.255L628.882 437.314c-12.496 12.497-32.758 12.497-45.255 0-12.496-12.497-12.496-32.758 0-45.255l225.432-225.432c12.497-12.496 32.758-12.496 45.255 0zM576 112a8 8 0 018 8v48a8 8 0 01-8 8v-64zm272 272a8 8 0 018-8h48a8 8 0 018 8h-64z"/><path pid="4" d="M901.756 113.236c5.536-1.044 10.388 3.809 9.344 9.345l-23.633 125.298c-1.192 6.318-8.972 8.72-13.518 4.174L772.283 150.388c-4.546-4.547-2.143-12.327 4.175-13.519l125.298-23.633zM352 557c0 35.346-28.654 64-64 64-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 35.346 0 64 28.654 64 64z"/>'
  }
})
